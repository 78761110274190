/**
 * @name: 表单校验规则集合
 * @author: itmobai
 * @date: 2023-05-31 14:13
 * @description：表单校验规则集合
 * @update: 2023-05-31 14:13
 */
/**
 * 判断正整数
 * @param rule
 * @param value
 * @param callback
 */
export const checkPositiveInteger = (rule: any, value: any, callback: any) => {
  if (value === null || value === "" || value === undefined) {
    return callback()
  }
  if (value == 0) {
    return callback()
  }
  if (!/^[0-9]*[1-9][0-9]*$/.test(value)) {
    return callback(new Error('请输入正整数'))
  }
  callback()
}

/**
 * 判断金额
 * @param rule
 * @param value
 * @param callback
 */
export const checkPrice = (rule: any, value: any, callback: any) => {
  if (value === null || value === "" || value === undefined) {
    return callback()
  }
  if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value)) {
    return callback(new Error('请输入有效金额'))
  }
  callback()
}

/**
 * 判断整数
 * @param rule
 * @param value
 * @param callback
 */
export const checkWhole = (rule: any, value: any, callback: any) => {
  if (value === null || value === "" || value === undefined) {
    return callback(new Error("请输入数字"))
  }
  if (value == 0) {
    return callback()
  }
  if (!/^-?[1-9]\d*$/.test(value)) {
    return callback(new Error('请输入整数'))
  }
  if (parseInt(value) < 0) {
    return callback(new Error('请输入正数'))
  }
  callback()
}
